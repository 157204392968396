import React from 'react'
import PropTypes from 'prop-types' // Компонента проверки на типизацию переменных
import classNames from 'classnames'


    const Button = ({ onClick, className, outline, children}) => { //деструализация переменной, массива
        return (
            <button
                onClick={ onClick}
                className={classNames('button', className, {'button--outline': outline} )}>
                 {children}
                 </button>
        )
    }




// // function Button (props) {
// const Button = (props) => {
//     return (
//         <button
//             onClick={ props.onClick}
//             className={classNames('button', props.className, {'button--outline': props.outline} )}>
//              {props.children}
//              </button>
//     )
// }



// class Buttom extends React.Component {

//     render() {
//         // return <button className={`button ${this.props.outline ? 'button--outline' : ''}`}>
//         return <button className={classNames('button', {'button--outline': this.props.outline} )}>
//             {this.props.children}
//             </button>

//     }

// }


Button.prototype = {
    onClick: PropTypes.func
}


export default Button;