import React from 'react'
import PropTypes from 'prop-types'

const Categories = React.memo(function Categories ({activeCategory, items, onClickCategory }) {
    return (
        <div className="categories">
            <ul>
            {items.length !== 0 ? <li className={activeCategory === null || activeCategory === undefined  ? 'active' : ''} onClick={() => onClickCategory(undefined)}>Все</li> :''}
                {items && items.map(({name, id}, index) => (
                    <li className={activeCategory === index ? 'active' : ''} 
                    onClick={() => onClickCategory(index)} 
                    key={`${id}`}
                    >{name}</li>
                ))} 
            </ul>
        </div>
    )
})

Categories.prototype = {
    activeCategory: PropTypes.number
}

export default Categories