import React from 'react'
import ContentLoader from "react-content-loader"

function LoadingBlock() {
       return (
        <ContentLoader
            className="pizza-block" 
            speed={1}
            width={300}
            height={441}
            viewBox="0 0 280 460"
            backgroundColor="#eaeaec"
            foregroundColor="#e0e0e0"
        >
            <circle cx="130" cy="121" r="118" /> 
            <rect x="5" y="263" rx="3" ry="3" width="280" height="36" /> 
            <rect x="5" y="306" rx="6" ry="6" width="280" height="84" /> 
            <rect x="5" y="415" rx="3" ry="3" width="90" height="23" /> 
            <rect x="105" y="396" rx="30" ry="30" width="177" height="57" />
        </ContentLoader>
    )
}

export default LoadingBlock
