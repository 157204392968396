import { SET_DISHES, SET_ITEMS_NO_FILTER, SET_LOADED } from "../constans"

const initialState = {
    items: [],
    isLoaded: false,
    noFilterItems: []
}

const Dishes = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISHES:
            return {
                ...state,
                items: action.payload,
                isLoaded: true,
            }
        case SET_LOADED:
            return {
                ...state,
                isLoaded: action.payload
            }
        case SET_ITEMS_NO_FILTER:
            return {
                ...state,
                noFilterItems: action.payload
            }
        default:
            return state
    }
}

export default Dishes