import { ADD_DISH, CLEAR_CART, MINUS_CART_ITEM, PLUS_CART_ITEM, REMOVE_CART_ITEM } from "../constans";

export const addDishToCart = (dishObj) => ({
    type: ADD_DISH,
    payload: dishObj
})

export const clearCart = () => ({
    type: CLEAR_CART,
})

export const removeCartItem = (id) => ({
    type: REMOVE_CART_ITEM,
    payload: id
})

export const plusCartItem = (id) => ({
    type: PLUS_CART_ITEM,
    payload: id
})

export const minusCartItem = (id) => ({
    type: MINUS_CART_ITEM,
    payload: id
})