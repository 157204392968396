import axios from 'axios'
import { BASE_URL, OBJECT_ID, OPTIONS_HEDERS, SET_DISHES, SET_ITEMS_NO_FILTER, SET_LOADED } from '../constans'


export const fetchDishes = (items, selectors, category) => (dispatch) => {
    if (items && (!category >= 0 || category === undefined)) {
        let arr = []

        if (category === undefined || category === null) {
            arr = items
        } else {
            arr = items.filter(id => selectors[category].dishes.includes(id.id))
        }

        const arrDishes = {
            dishes: [...arr]
        }
        dispatch(setDishes(arrDishes))
    } else {

        dispatch(setLoaded(false))
        axios.get(`${BASE_URL}dishes?objectid=${OBJECT_ID}&lang=ru`, OPTIONS_HEDERS)
            .then(({ data }) => {
                dispatch(setDishes(data.data))
                dispatch(setItemsNoFilters(data.data))
            })
    }


}


export const setLoaded = (payload) => ({
    type: SET_LOADED,
    payload
})


export const setDishes = (items) => ({
    type: SET_DISHES,
    payload: items
})

export const setItemsNoFilters = (items) => ({
    type: SET_ITEMS_NO_FILTER,
    payload: items
})