import axios from 'axios'
import { BASE_URL, OBJECT_ID, OPTIONS_HEDERS, SET_SELECTOR } from '../constans'


export const fetchSelectors = () => (dispatch) => {

    axios.get(`${BASE_URL}menutree?objectid=${OBJECT_ID}&lang=ru`, OPTIONS_HEDERS)
        .then(({ data }) => {
            dispatch(setSelectors(data.data.selectors[0].items))
        })
}

export const setSelectors = (items) => ({
    type: SET_SELECTOR,
    payload: items
})