import { combineReducers } from 'redux'
import dishes from './dishes'
import selectors from './selectors'
import filters from './filters'
import cart from './cart'

const rootReducer = combineReducers({
    dishes,
    selectors,
    filters,
    cart


})

export default rootReducer