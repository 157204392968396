import axios from 'axios'
import { BASE_URL, OBJECT_ID, OPTIONS_HEDERS, OPTIONS_HEDERSS } from '../constans'

export const saveOrder = () => (dispatch) => {

    const arrOrder = {
        "objectid": OBJECT_ID,
        "delivery_time": "2021-05-02T19:00:00",
        "comment": " Оплата онлайн нал",
        "order_type": 0,
        "pay_type": 0,
        "client": {
            "phone": "89999999999",
            "email": "test@it-hm.ru",
            "ln": "Тестов",
            "fn": "Тест"
        },
        order: [{
            "id": "1000740",
            "type": "d",
            "qnt": 1000,
            "items": []
        }]
    }
    const uuu = '/api/v1/'

    axios.post(`${BASE_URL}savedeliveryorder`, arrOrder, OPTIONS_HEDERS)
        .then(({ data }) => {
            console.log(data)
                // dispatch(setDishes(data.data))
                // dispatch(setItemsNoFilters(data.data))
        })

}