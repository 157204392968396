export const SET_DISHES = 'SET_DISHES'
export const SET_LOADED = 'SET_LOADED'
export const SET_ITEMS_NO_FILTER = 'SET_ITEMS_NO_FILTER'

export const SET_SELECTOR = 'SET_SELECTOR'
export const SET_CATEGORY = 'SET_CATEGORY'

export const ADD_DISH = 'ADD_DISH'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const CLEAR_CART = 'CLEAR_CART'
export const PLUS_CART_ITEM = 'PLUS_CART_ITEM'
export const MINUS_CART_ITEM = 'MINUS_CART_ITEM'

export const OPTIONS_HEDERS = {
    headers: { 'sid': 'BqTml8IDIE6D8TmhBfZCb9Kk7jv3WECuiST4ZxM9YhAyLCdKOfLAD3yCftpBNPqE' }
}

export const OPTIONS_HEDERSS = {
    headers: {
        'sid': 'BqTml8IDIE6D8TmhBfZCb9Kk7jv3WECuiST4ZxM9YhAyLCdKOfLAD3yCftpBNPqE',
        // 'mode': 'no-cors',
        // 'Origin': 'http://192.168.111.130',
        // 'Host': 'https://dlv.ucs.ru',
        // 'Access-Control-Allow-Origin': 'http://192.168.111.130',
        // 'Access-Control-Allow-Methods': 'POST, GET',
        // 'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, X-Requested-With'
    }
}

export const BASE_URL = 'https://dlv.ucs.ru/api/v1/'
export const OBJECT_ID = 322690001