import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ItemBlock from '../components/ItemBlock'
import ItemsLoadingBlock from '../components/ItemBlock/LoadingBlock'
import  Categories  from '../components/Categories'
import { setCategory } from '../redux/actions/filters'
import { fetchDishes } from '../redux/actions/dishes'
import { fetchSelectors } from '../redux/actions/selectors'
import { addDishToCart } from '../redux/actions/cart'

// let itemsNoFilter

function Home() {

    const dispatch = useDispatch()
    const items = useSelector(({ dishes }) => dishes.items.dishes)
    const itemsNoFilter = useSelector(({ dishes }) => dishes.noFilterItems.dishes)
    const cartItems = useSelector(({ cart }) => cart.items)
    const isLoaded = useSelector(({ dishes }) => dishes.isLoaded)
    const selectors = useSelector(({ selectors }) => selectors.items)
    const selectorsLoaded = useSelector(({ selectors }) => selectors.isLoaded)
    const { category } = useSelector(({filters}) => filters)

    React.useEffect (() => {
        dispatch(fetchDishes(itemsNoFilter, selectors, category))   
        const tim = setTimeout (() => {
            if ( window.pageYOffset > 100) {
                let i = window.pageYOffset
                const int = setInterval(() => {
                    window.scrollTo(0, i)
                    i-=30
                    if (i <= 0) {clearInterval(int) } 
                }, 5)
            }
            clearInterval(tim)
        }, 100)
        
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        //   });
    },[category]) // eslint-disable-line react-hooks/exhaustive-deps
  
    React.useEffect (() => {
        // itemsNoFilter = items
    },[isLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect (() => {
        console.log('SL',selectorsLoaded)
        !selectorsLoaded && dispatch(fetchSelectors())
        
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSelectCategory = React.useCallback((index) => {
        dispatch(setCategory(index))    
      }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const handleAddDishToCart = obj => {
        dispatch(addDishToCart(obj))
    }  

    return (
        <div className="container">
            <div className="content__top">
               <Categories 
               activeCategory={category}
               items={selectors}
               onClickCategory={onSelectCategory} 
               /> 
            </div>
            <div className="content__items">
                {
                    isLoaded ? items.map((obj) => (
                        
                        <ItemBlock 
                        onClickAddDish={handleAddDishToCart}
                        key={obj.id}
                        addedCount={cartItems[obj.id] && cartItems[obj.id].items.length}
                        {...obj}
                        />
                    )) : Array(10).fill(0).map((_,index) => <ItemsLoadingBlock key={index}/>)
                }
            </div>
        </div>
    )
}

export default Home
