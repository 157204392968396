import { SET_SELECTOR } from "../constans"

const initialState = {
    items: [],
    isLoaded: false
}

const selectors = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTOR:
            return {
                ...state,
                items: action.payload,
                isLoaded: true
            }
        default:
            return state
    }

}

export default selectors